<template>
  <div :class="rootClasses" :style="rootStyles">
    <div class="sharedPrice__wrapper">
      <slot name="before_icon"></slot>
      <slot name="before"></slot>
      <SharedText
        class="price__title"
        :color="props.color"
        :weight="FontWeights.BLACK"
        :size="priceTitleSize"
        font-case="upper"
        wrap="nowrap"
        v-bind="props.priceTextProps || {}"
      >
        <BaseCurrency v-if="!hideCurrency">{{ priceTitle }}</BaseCurrency>
      </SharedText>
      <SharedText
        v-if="oldPrice && !isRangeVariant"
        class="price__old"
        :weight="FontWeights.BOLD"
        :size="oldPriceSize"
        :color="props.color"
        wrap="nowrap"
        v-bind="props.oldPriceTextProps || {}"
      >
        <BaseCurrency>{{ priceOldWithCurrency }}</BaseCurrency>
      </SharedText>
      <SharedText v-if="title" as="div" v-bind="bindTitleProps" :size="priceTitleSize" :weight="FontWeights.BOLD">
        {{ title.text }}
      </SharedText>
      <div v-if="slots.after">
        <slot name="after"></slot>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { CSSProperties } from 'vue';
import { computed } from 'vue';
import type { IPropsSharedPrice } from './SharedPrice.types';
import type { ITextProps } from '~/types/SharedFont.types';
import { FontSizes, FontSizesNumber, FontWeights } from '~/types/SharedFont.types';
import { Breakpoints } from '~/constants/media.constants';
import { defaultTitleOptions } from '~/components/SharedPrice/SharedPrice.data';

const { t } = useI18n();

const ROOT_BG_COLOR = '--root-price-bg-color';
const ROOT_BOX_SHADOW = '--root-box-shadow';
const ROOT_BORDER_RADIUS = '--root-border-radius';

const FREE_VARIANT_TITLE = t('lostLocales.freePrice');

const COMPONENT_NAME = 'sharedPrice';

const slots = defineSlots();

const props = withDefaults(defineProps<IPropsSharedPrice>(), {
  price: '100',
  size: 'medium',
  variant: 'price',
});

const { variant, price, oldPrice, size, title } = toRefs(props);

const isRangeVariant = computed(() => variant.value === 'range');

const rootClasses = computed(() => ({
  [COMPONENT_NAME]: true,
  [`${COMPONENT_NAME}--${props.size}`]: props.size,
  [`${COMPONENT_NAME}--${props.theme}`]: props.theme,
}));

const rootStyles = computed(() => {
  const properties: CSSProperties = {};

  if (props.backgroundColor && !props.theme) {
    const colors = GlobalUtils.CSS.parseColor(props.backgroundColor);
    properties[ROOT_BG_COLOR] = colors.backgroundColor || colors.backgroundImage;
  }
  if (props.boxShadow) properties[ROOT_BOX_SHADOW] = props.boxShadow;
  if (props.radius) properties[ROOT_BORDER_RADIUS] = GlobalUtils.CSS.proceedCssValue(props.radius);

  if (props.color) properties.color = props.color;

  return properties;
});

const viewport = useViewport();

const priceTitleSize = computed(() => {
  if (size.value === 'small')
    return viewport.isGreaterOrEquals(Breakpoints.xl) ? FontSizesNumber.LARGE_XL : FontSizesNumber.SMALL;
  else if (size.value === 'medium')
    return viewport.isGreaterOrEquals(Breakpoints.xl) ? FontSizesNumber.LARGE : FontSizesNumber.MEDIUM;
  else if (size.value === 'smallest')
    return viewport.isGreaterOrEquals(Breakpoints.xl) ? FontSizesNumber.LARGE : FontSizesNumber.SMALL_2XS;
  else return FontSizesNumber.LARGE_XL;
});

const oldPriceSize = computed(() => {
  if (size.value === 'small') return viewport.isGreaterOrEquals(Breakpoints.xl) ? FontSizes.SMALL : FontSizes.SMALL_2XS;
  else if (size.value === 'medium') return FontSizes.SMALL_XS;
  else return FontSizes.SMALL;
});
const priceTitle = computed(() => {
  if (variant.value === 'price' && price?.value) return price.value;
  else if (variant.value === 'free') return FREE_VARIANT_TITLE;
  else if (variant.value === 'range' && Array.isArray(props.price)) return props.price.join('-');
  else return price?.value;
});

const bindTitleProps = computed<ITextProps>(() => {
  return title?.value?.textProps ? title.value.textProps : defaultTitleOptions;
});

const priceOldWithCurrency = computed(() => props.oldPrice || 0);
</script>

<style src="./SharedPrice.scss" lang="scss" scoped></style>
